/* Base styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Headings */
h1 {
  font-size: 24px; /* Uses size from file two */
  font-weight: bold;
  margin: 0; /* Removes space above and below the heading */
  padding: 5px 0; /* Removes any top/bottom padding if present */
}

h2 {
  font-size: 20px; /* Common size, with added line-height from file two */
  font-weight: bold;
  margin: 0; /* Removes space above and below the heading */
  padding: 5px 0; /* Removes any top/bottom padding if present */
}

h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0; /* Removes space above and below the heading */
  padding: 5px 0; /* Removes any top/bottom padding if present */
}

h4 {
  font-size: 1em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5); /* 50% transparent black */
  margin: 0;
  padding: 0.5; /* Included from file one */
}

/* Paragraph styles */
p {
  font-size: 12px;
  margin: 0;
  padding: 2px; /* From file one */
  line-height: 1.6; /* From file two */
  color: inherit; /* Ensure the text color is inherited from parent */
}

.small-text {
    font-size: 8px; /* Adjust the size as needed */
}
.small-link {
  color: inherit; /* Inherit color from parent element (e.g., from <p>) */
  cursor: auto;
  font-size: 8px; /* From file one */
  text-align: center; /* From file two */
  text-decoration: none; /* Ensure the text is underlined */
}

.link, a {
  color: inherit; /* Inherit color from parent element (e.g., from <p>) */
  cursor: auto;
  font-size: 10px; /* From file one */
  padding: 2px; /* From file one */
  text-align: center; /* From file two */
  text-decoration: none; /* Ensure the text is underlined */
}

/* Remove default link styling */
a:link,
a:visited {
  color: black; /* Match this to the <p> color */
}

a:hover,
a:active {
  color: black; /* Optional: Change color on hover or active */
}

b {
  font-size: 1em;
}


/* Button styles */
.tree-button,
.pulsing-button,
.static-button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid white;
}

.tree-button {
  background-color: #4f772d;
}

.pulsing-button {
  background-color: blue;
  animation: pulse 2s infinite;
}

.static-button {
  background-color: blue;
}

/* Button animations */
@keyframes pulse {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.8);
  }
}

/* Layout styles */
.content-wrapper {
  display: flex;
  flex-direction: row; /* Default to row for desktop view */
  height: 100%;
  max-height: 100vh;
  max-height: 100svh;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 20px; /* From scrollableContentStyle */
  gap: 20px; /* From scrollableContentStyle */
}

/* Scrollable content columns */
.info-column,
.image-column {
  flex: 1; /* Equal width for both columns (from columnStyle and imageColumnStyle) */
  box-sizing: border-box; /* Ensures padding doesn't affect size (from columnStyle) */
}

/* Ensure columns stack on smaller screens */
@media (max-width: 700px) {
  .content-wrapper {
    flex-direction: column; /* Change to column layout */
  }
  .info-column,
  .image-column {
    width: 100%; /* Each column takes full width */
  }
}

.image-column {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  max-height: 100svh;
  padding: 10px;
  text-align: center;
}

.image-column img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 5px;
  width: 80%;
  height: auto;
}

/* Media query for larger screens */
@media (min-width: 700px) {
  .info-column,
  .image-column {
    width: 50%; /* Each column takes half the width */
  }
}

/* Header and footer styles */
.header-box,
.footer-box {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  color: black;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
  text-align: center; /* Center text horizontally */
}

.header-box {
  padding: 10px;
  top: 10px;
  right: 10px;
  width: 220px;
  height: 85px;
  border-radius: 5px;
}

.footer-box {
  bottom: 18px;
  right: 0px;
  padding: 4px;
  width: 215px;
}

/* Overlay styles */
.overlay-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  heigh: 100svh;
  background-color: rgba(0, 0, 0, 0); /* Semi-transparent black background */
  z-index: 999; /* Background layer behind the content */
}

.overlay-box {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80vw;
  height: 80vh;
  transform: translate(-50%, -50%); /* Center the box */
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  color: black;
  z-index: 1000; /* Content layer above the background */
  border-radius: 5px;
  padding-top: 40px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column; /* Layout for the content */
}

/* Scrollable content wrapper */
.content-wrapper {
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
  box-sizing: border-box;
}

/* Customize the scrollbar */
.content-wrapper::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.content-wrapper::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0); /* Semi-transparent white background for the scrollbar track */
  border-radius: 10px;
}

.content-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.8);  /* Darker color for the scrollbar thumb */
  border-radius: 5px;
  border: 2px solid rgba(255, 255, 255, 0.2); /* Transparent border to blend thumb with track */
}

/* Location marker styles */
.location-button {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
}

/* Map toggle button styles */
.map-toggle-button {
  position: absolute;
  top: 70px; /* Adjusted to place below the location button */
  left: 12px;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
}


/* Map toggle button styles */
.filter-toggle-button {
  position: absolute;
  bottom: 12px; /* Adjusted to place below the location button */
  left: 12px;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
}



/* Map toggle button styles */
.statistics-toggle-button {
  position: absolute;
  bottom: 70px; /* Adjusted to place below the location button */
  left: 12px;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
}


.checkbox-container {
  display: flex;  
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 5px; /* Space between checkboxes */
  justify-content: left; /* Center align checkboxes */
  padding: 0px; /* Add padding around the container */
  padding-top: 20px;
  overflow-y: auto; /* Allow vertical scrolling */
}

.checkbox-container-over {
  display: flex;  
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 5px; /* Space between checkboxes */
  justify-content: left; /* Center align checkboxes */
  padding-top: 20px; /* Add padding around the container */
}

.checkbox-label {
  display: flex;
  align-items: center; /* Center the checkbox and text vertically */
  flex: 0 0 200px; /* Set a fixed width for each checkbox item */
  margin: 5px 0; /* Add margin between items */
  font-size: 12px;
}

.circle-checkbox {
  width: 8px; /* Width of the circle */
  height: 8px; /* Height of the circle */
  border-radius: 50%; /* Make it circular */
  border: 2px solid white;
  margin-right: 10px; /* Space between circle and label text */
}
