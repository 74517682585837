
/* Marker clusters */

.custom-cluster-icon {
  color: black; /* Color of the text inside the cluster */
  border: 0px solid white;
  text-align: center;
  line-height: 40px; /* Match the height of the div */
}

.maker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
  background-color: rgba(255, 255, 255, 0.8);
  border: 0.2px solid white;
  color: black;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
}

.marker-cluster-small {
  background-color: rgba(255, 255, 255, 0.8);
  border: 0.2px solid white;
  color: black;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */

}

.marker-cluster-medium {
    background-color: rgba(255, 255, 255, 0.8);
  border: 0.2px solid white;
  color: black;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
}

.marker-cluster-large {
    background-color: rgba(255, 255, 255, 0.8);
  border: 0.2px solid white;
  color: black;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
}

.custom-marker-icon .custom-marker-inner {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid white;
}
